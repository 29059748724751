import React, { Component } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import Pagination from "react-js-pagination";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { translations } from '../../../translate.js';
import WholesaleOrdersForm from "./forms/wholesaleOrdersForm";
import ReviewPurchaseOrder from "./review/reviewPurchaseOrder";
import AddOrder from "./forms/addOrder";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

var today = new Date();
var currentDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

class WholesaleOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0,
            limit: 25,
            activePage: 1,
            dataCount: 0,
            errorMsg: '',
            cartList: [],
            cartTable: [],
            selectedDistributor: [],
            distributorList: [],
            selectedStatus: '',
            statusList: [],
            selectedCart: [],
            orderId: '',
            fromDate: currentDate,
            toDate: currentDate,
            openleftModal: false,
            data: [],
            status: '',
            reviewOrders: false,
            ordersData: [],
            openOrderModal: false,



        }
    }
    handleRefresh = () => {
        // clear all filter data
        this.setState({
            loading: 0,
            activePage: 1,
            selectedDistributor: [],
            selectedCart: [],
            selectedStatus: [],
            orderId: '',
            fromDate: currentDate,
            toDate: currentDate,
            distId: '',
            cartId: '',
        }, function () {
            this.getBulkOrderCarts()
        });
    }

    componentDidMount() {
        this.getAllDist()
        this.getBulkOrderCarts('1')
        this.getBulkOrderCarts()
        this.getBulkCartStatus()
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.target.className != undefined) {
            if (!event.target.classList.contains('actionDropdownList')) {
                this.setState({
                    openHeader: ''
                })
            }
        }
    }

    getAllDist = () => {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllDist',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'getType': 'all'
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ distributorList: data['data'] })
                }
            }
        })
    }

    getBulkOrderCarts = (cartType) => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getBulkOrderCarts',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'status': cartType == '1' ? '' : this.state.selectedStatus == undefined || this.state.selectedStatus == null || this.state.selectedStatus == '' ? '' : this.state.selectedStatus,
                'nameOnly': cartType == '1' ? '1' : '',
                'distId': cartType == '1' ? '' : this.getSelected(),
                'cartId': cartType == '1' ? '' : this.getSelected('cart'),
                'fromDate': cartType == '1' ? '' : this.state.fromDate,
                'toDate': cartType == '1' ? '' : this.state.toDate,
                'orderId': cartType == '1' ? '' : this.state.orderId,
                'start': cartType == '1' ? '' : this.state.activePage,
                'limit': cartType == '1' ? '' : this.state.limit,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    if (cartType == '1') {
                        this.setState({ cartList: data['data'], loading: 1 })
                    } else {
                        this.setState({ cartTable: data['data'], loading: 1, dataCount: parseInt(data['count']) })
                    }


                }
            }
        })
    }

    getBulkCartStatus = () => {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getBulkCartStatus',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ statusList: data['data'] })
                }
            }
        })
    }

    buyCartAndExportInvoice = (event, id) => {
        event.preventDefault()
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'buyCartAndExportInvoice',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'cartId': id
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    // console.log(data);
                    if (data['error'] == '1') {
                        this.msgErrorbuyingCart(event, data['errorMsg'])
                    } else {
                        this.getBulkOrderCarts()
                    }
                }
            }
        })
    }
    deleteCart = (event, id) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-3">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5>{Strings['Are you sure ?']}</h5>
                        <p>{Strings['You want to delete this cart ?']}</p>
                        <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                        <button type="button" className="btn form-save-btn"
                            onClick={() => {
                                this.handleClickDelete(id);
                                onClose();
                            }}
                        >
                            {Strings.Yes}
                        </button>
                    </div>
                );
            }
        });
    }

    handleClickDelete = (id) => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'deleteCart',
                'cartId': id,
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ activePage: 1, loading: 0 }, function () {
                        this.getBulkOrderCarts()
                    });
                }
            }
        })
    }

    reviewOrders = (event, orderData) => {
        this.setState({ reviewOrders: true, data: orderData })
    }
    handleCloseReviewOrders = () => {
        this.setState({ reviewOrders: false, data: [] })
    }

    openOrderModal = (event, data) => {
        this.setState({ openOrderModal: true, data: data })
    }
    handleCloseOrderModal = (event) => {
        this.setState({ openOrderModal: false })
    }
    buyingCart = (event, id, cartName, distName) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-4">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5>{Strings['Confirm adding orders to']}</h5>
                        <p className="mb-3 ">{Strings['Distributor']} <strong>"{distName}"</strong> {Strings['from the cart']} <strong>"{cartName}"</strong> {Strings['number']} <strong>{id}</strong></p>
                        <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                        <button type="button" className="btn form-save-btn"
                            onClick={() => {
                                this.buyCartAndExportInvoice(event, id);
                                onClose();
                            }}
                        >
                            {Strings.Confirm}
                        </button>
                    </div>
                );
            }
        });
    }

    msgErrorbuyingCart = (event, error) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-4">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5>{Strings['Error']}</h5>
                        <p className="mb-3 ">{error}</p>
                        <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                    </div>
                );
            }
        });
    }
    handleChangeSelect = (type, option) => {
        this.setState(state => {
            return {
                [type]: option,
                activePage: 1

            }
        }, function () {

            if (type == 'selectedDistributor') {
                // this.setState({ distId: option.userId }, function () {
                this.getBulkOrderCarts()
                // })
            }
            if (type == 'selectedCart') {
                // this.setState({ cartId: option.id }, function () {
                this.getBulkOrderCarts()
                // })
            }
        })

    }
    setUpSelectOptions = (type) => {
        var list = ''
        if (type == 'selectedStatus') {
            if (this.state.statusList != null && this.state.statusList != undefined && this.state.statusList.length > 0) {
                list = this.state.statusList.map((rowData, index) => {
                    return (
                        <option value={rowData.id + 1} key={index}>
                            {rowData.name}
                        </option>
                    )
                })
            }
        }
        return list
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            activePage: 1
        }, function () {
            this.getBulkOrderCarts()
        })
    }
    getSelected = (type = 'dist') => {
        if (type == 'dist') {
            return this.state.selectedDistributor.map(data => data.userId);
        }

        if (type == 'cart') {
            return this.state.selectedCart.map(data => data.id);
        }
    }


    exportHandler = () => {
        var selectedStatusId = this.state.selectedStatus
        if (selectedStatusId == '' || selectedStatusId == null || selectedStatusId == undefined) {
            selectedStatusId = ''
        }

        // var distId = this.state.distId
        // if (distId == '' || distId == null || distId == undefined) {
        //     distId = ''
        // }
        // var cartId = this.state.cartId
        // if (cartId == '' || cartId == null || cartId == undefined) {
        //     cartId = ''
        // }
        var fromDate = this.state.fromDate
        if (fromDate == '' || fromDate == null || fromDate == undefined) {
            fromDate = currentDate
        }

        var toDate = this.state.toDate
        if (toDate == '' || toDate == null || toDate == undefined) {
            toDate = currentDate
        }

        var orderId = this.state.orderId
        if (orderId == '' || orderId == null || orderId == undefined) {
            orderId = ''
        }



        window.location = window.API_PERM_USER + "/permUser.php?type=" + "getBulkOrderCarts" + "&lang=" + localStorage.getItem('LANG') + "&userId=" + localStorage.getItem('USER_ID')
            + "&token=" + localStorage.getItem('USER_TOKEN') +
            "&status=" + selectedStatusId +
            "&fromDate=" + fromDate +
            "&toDate=" + toDate +
            "&cartId=" + this.getSelected('cart') +
            "&distId=" + this.getSelected() +
            "&orderId=" + orderId +
            "&export=1"
    }

    exportHandlerBuyCart = (cartId) => {

        window.location = window.API_PERM_USER + "?type=buyCartAndExportInvoice&lang=" + localStorage.getItem('LANG') + "&userId=" + localStorage.getItem('USER_ID') +
            "&token=" + localStorage.getItem('USER_TOKEN') + "&cartId=" + cartId
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getBulkOrderCarts()
        });
    }

    openSidebarModal = (event, status, data) => {
        this.setState({ openleftModal: true, status: status, data: data })
    }

    handleCloseSideBar = () => {
        this.setState({ openleftModal: false, status: '', data: [] })
    }

    openHeaderMenu = (event, id) => {
        event.preventDefault();
        if (event.target.classList[1] != undefined && event.target.classList[1] == "menu-dots-vertical" && id != undefined) {
            var val = ''
            if (this.state.openHeader == id) {
                val = ''
            } else if (id != undefined) {
                val = id
            }
            this.setState({
                openHeader: val
            })
        } else if (id == undefined && event.target.classList[1] != "menu-dots-vertical") {
            this.setState({
                openHeader: ''
            })
        }
    }

    displayData = () => {
        var cartInfo = ''
        var classStyle = { 'text-0': 'succ-color', 'text-1': 'gray-color' }
        var classColStatus = ''
        if (this.state.loading == 1 && this.state.cartTable.length > 0) {
            cartInfo = this.state.cartTable.map((rowData, index) => {
                if (rowData['status'] !== '') {
                    classColStatus = classStyle['text-' + rowData['status']];
                }
                classColStatus = ' overflow-text-length  bold-font ' + classColStatus + ' '
                return (
                    <Row key={index} className='table-body-row'>
                        <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length" >#{rowData['id']}</Col>
                        <Col lg={3} md={2} sm={4} xs={6} className="overflow-text-length" title={rowData['cartName']}>{rowData['cartName']}</Col>
                        <Col lg={3} md={2} sm={4} xs={6} className="overflow-text-length">{rowData['fname'] + ' ' + rowData['lname']}</Col>
                        <Col lg={1} md={2} sm={4} xs={6} className={classColStatus}>{rowData['status_name']}</Col>
                        {rowData['status'] == "0" ?
                            <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length menu-dots-vertical pointer-cursor" onClick={(event) => this.buyingCart(event, rowData['id'], rowData['cartName'], rowData['fname'] + ' ' + rowData['lname'])}><AddShoppingCartIcon /></Col>
                            :
                            rowData['status'] != "0" && rowData['fileName'] != '' ?
                                <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length"><a target="_blank" href={rowData['fileName']}><PictureAsPdfIcon className="menu-dots-vertical" /></a></Col>
                                :
                                <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length"><div onClick={() => this.exportHandlerBuyCart(rowData['id'])}><PictureAsPdfIcon className="menu-dots-vertical" /></div></Col>
                        }
                        <Col lg={2} md={2} sm={4} xs={6} className="overflow-text-length">{rowData['entryDate']}</Col>
                        <Col lg={1} md={2} sm={4} xs={6} className={'action-icon-view actionDropdownList actionDropdownListPadding-0 text-md-right'}  >
                            <Dropdown isOpen={(this.state.openHeader == rowData['id'] ? true : false)} className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist" >
                                <DropdownToggle tag="a" className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0" caret  >
                                    <MoreVertIcon className='menu-dots-vertical' onClick={(event) => this.openHeaderMenu(event, rowData['id'])} />
                                </DropdownToggle>

                                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList" >
                                    <div className="actionDropdownList" style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                                        {rowData['status'] == "0" &&
                                            <DropdownItem className='actionDropdownList' onClick={(event) => this.openOrderModal(event, rowData)}>{Strings['Add Order']}</DropdownItem>
                                        }
                                        <DropdownItem className='actionDropdownList' onClick={(event) => this.reviewOrders(event, rowData)}>{Strings['Review purchase orders']}</DropdownItem>
                                        {/* <DropdownItem className='actionDropdownList' onClick={(event) => this.buyingCart(event, rowData['id'], rowData['cartName'], rowData['distName'])}>{Strings['Buying']}</DropdownItem> */}
                                        {rowData['status'] == "0" &&
                                            <DropdownItem className='actionDropdownList' onClick={(event) => this.openSidebarModal(event, 'edit', rowData)}>{Strings['Edit']}</DropdownItem>
                                        }
                                        {rowData['status'] == "0" &&
                                            <DropdownItem className='actionDropdownList' onClick={(event) => this.deleteCart(event, rowData['id'])}>{Strings['Delete']}</DropdownItem>
                                        }
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                )
            })
        }
        return cartInfo;
    }


    render() {

        return (
            <div>

                <Row>
                    <Col md={4} className='main-color-1 inv-class-view-title mb-4'>
                        <div>{Strings['Wholesale Orders']}</div>
                        <hr className='inv-hr-view-2' />
                    </Col>
                </Row>
                {/* ///FiliterView///// */}
                <Row className='page-filter-view'>
                    <Col lg={5} md={7}>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <Row className='filter-form'>
                                <Col md={6} lg={6} sm={6} xs={12}>
                                    <div className='member-searchable-list mb-2'>
                                        <Picky
                                            value={this.state.selectedDistributor}
                                            options={this.state.distributorList}
                                            onChange={this.handleChangeSelect.bind(this, 'selectedDistributor')}
                                            open={false}
                                            valueKey="userId"
                                            labelKey='fname'
                                            multiple={true}
                                            includeSelectAll={true}
                                            includeFilter={true}
                                            dropdownHeight={200}
                                            placeholder={Strings['Distributor Name']}
                                            filterPlaceholder={Strings['Select Distributor Name']}
                                            selectAllText={Strings["Select All"]}
                                            allSelectedPlaceholder={Strings["%s selected"]}
                                            manySelectedPlaceholder={Strings["%s selected"]}
                                            clearFilterOnClose={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} lg={4} sm={4} xs={12}>
                                    <AvField className={'margin-Bottom-Responsive-8'} name="orderId" type='number' placeholder={Strings['Order Number']} value={this.state.orderId} onChange={this.handleInputChange} />
                                </Col>
                                <Col md={2} lg={2} sm={2} xs={12} className='mb-2'>
                                    <Button variant="contained" className='refresh-action-btn' onClick={this.handleRefresh} title={Strings['Refresh']}>
                                        <i className='flaticon-refresh reset-filter-btn'></i>
                                    </Button>
                                </Col>
                                <Col md={6} lg={6} sm={6} xs={12}>
                                    <div className='member-searchable-list mb-2'>
                                        <Picky
                                            value={this.state.selectedCart}
                                            options={this.state.cartList}
                                            onChange={this.handleChangeSelect.bind(this, 'selectedCart')}
                                            open={false}
                                            valueKey="id"
                                            labelKey="cartName"
                                            multiple={true}
                                            includeSelectAll={true}
                                            includeFilter={true}
                                            dropdownHeight={200}
                                            placeholder={Strings['Cart Name']}
                                            filterPlaceholder={Strings['Select Cart Name']}
                                            selectAllText={Strings["Select All"]}
                                            allSelectedPlaceholder={Strings["%s selected"]}
                                            manySelectedPlaceholder={Strings["%s selected"]}
                                            clearFilterOnClose={true}
                                        />
                                    </div>


                                </Col>

                                <Col md={6} lg={6} sm={6} xs={12}>

                                    <AvField type="select" name="selectedStatus" value={this.state.selectedStatus} onChange={(event) => this.handleInputChange(event)}  >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" defaultValue>{Strings['Select Status']}</option>
                                        {this.setUpSelectOptions('selectedStatus')}
                                    </AvField>
                                </Col>

                                <Col md={6} lg={6} sm={6} xs={12}>
                                    <AvField name="fromDate" type='date' label={Strings['From Date']} value={this.state.fromDate} onChange={this.handleInputChange} />
                                </Col>
                                <Col md={6} lg={6} sm={6} xs={12}>
                                    <AvField name="toDate" type='date' label={Strings['To Date']} value={this.state.toDate} onChange={this.handleInputChange} />
                                </Col>

                            </Row>
                        </AvForm>
                    </Col>
                    <Col lg={7} md={5} className='text-right'>
                        <button className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1 mt-2 mt-md-0" onClick={(event) => this.openSidebarModal(event, 'add', '')}>
                            {Strings['Add Cart']}
                        </button>
                        <button className="btn page-action-btn ml-1" onClick={(event) => this.exportHandler(event)}>
                            {Strings['Export']}
                        </button>

                    </Col>
                </Row>


                {this.state.loading == 0 && this.state.errorMsg != '' &&
                    <Row>
                        <div className='inv-cards-view pb-5 width-100'>
                            <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                <div className='no-data-text'>{this.state.errorMsg}</div>
                            </div>
                        </div>
                    </Row>
                }

                {this.state.loading == 1 && this.state.cartTable.length <= 0 &&
                    <Row>
                        <div className='inv-cards-view pb-5 width-100'>
                            <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                            </div>
                        </div>
                    </Row>
                }

                {this.state.loading == 1 && this.state.cartTable.length > 0 &&
                    <Row className='inv-cards-view'>
                        <Col>
                            <Row className='table-header-row'>
                                <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length" title={Strings['Cart #']}>{Strings['Cart #']}</Col>
                                <Col lg={3} md={2} sm={4} xs={6} className="overflow-text-length" title={Strings['Cart Name']}>{Strings['Cart Name']}</Col>
                                <Col lg={3} md={2} sm={4} xs={6} className="overflow-text-length" title={Strings['Distributor Name']}> {Strings['Distributor Name']}</Col>
                                <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length" title={Strings['Status']}>{Strings['Status']}</Col>
                                <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length" title={Strings['Buying']}>{Strings['Buying']}</Col>
                                <Col lg={2} md={2} sm={4} xs={6} className="overflow-text-length" title={Strings['Date']}>{Strings['Date']}</Col>
                                <Col lg={1} md={2} sm={4} xs={6} className="overflow-text-length text-md-right" title={Strings['Action']}> {Strings['Action']}</Col>
                            </Row>
                            {this.displayData()}
                        </Col>
                        <Col md={12}>
                            <Row className='pagenation-view mt-3 mb-4'>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.limit}
                                    totalItemsCount={this.state.dataCount}
                                    pageRangeDisplayed={4}
                                    onChange={this.handlePageChange}
                                    prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next' : 'flaticon2-back'}></i>}
                                    nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back' : 'flaticon2-next'}></i>}
                                    hideFirstLastPages
                                    innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                    itemClass={'page-item'}
                                    linkClass={'page-link'}
                                />
                            </Row>
                        </Col>


                    </Row >
                }
                {
                    this.state.loading == 0 &&
                    <Row>
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }

                <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModal} className='sidebar-modal-view'>
                    <WholesaleOrdersForm
                        handleCloseSideBar={this.handleCloseSideBar}
                        status={this.state.status}
                        data={this.state.data}
                        distributorList={this.state.distributorList}
                        getBulkOrderCarts={this.getBulkOrderCarts} />
                </Drawer>
                <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.reviewOrders} className='sidebar-modal-large-view'>
                    <ReviewPurchaseOrder
                        handleCloseSideBar={this.handleCloseReviewOrders}
                        data={this.state.data} />
                </Drawer>

                <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openOrderModal} className='sidebar-modal-large-view'>
                    <AddOrder
                        handleCloseSideBar={this.handleCloseOrderModal}
                        data={this.state.data}
                        getBulkOrderCarts={this.getBulkOrderCarts}
                    />
                </Drawer>
            </div >
        )
    }
}
export default WholesaleOrders;