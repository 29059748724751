import React, { Component } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from "react-js-pagination";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Drawer, Button } from "@material-ui/core";
import ViewOrder from '../../orders/forms/viewOrder'
import PullCards from './pullCard'
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;



class AccountStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0,
            activePage: 1,
            dataType: 1,
            limit: 25,
            dateFrom: '',
            dateTo: '',
            openHeader: '',
            actionType: '',
            emptyFromDate: '',
            emptyToDate: '',
            openleftModal: false,
            loadingCardClass: 0,
            dataCount: 0,
            data: [],
            errorMsg: '',distId:this.props.distId?this.props.distId:'',
        }
    }

    componentDidMount() {
        this.getInventoryStatement()
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleRefresh = () => {
        // clear all filter data
        this.setState({
            loading: 0,
            data: [],
            dataCount: 0,
            emptyFromDate: '',
            emptyToDate: '',
            dateFrom: '',
            dateTo: '',
            activePage: 1,
            dataType: 1,
            msgError: ''
        }, function () {
            this.getInventoryStatement();
        });
    }
    getInventoryStatement = (pullCard = 0, fileName = '') => {
        if (pullCard == 1) {
            this.setState({ loading: 1, dataType: 2, activePage: 1 })
        } else {
            this.setState({ loading: 1 })
        }
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getInventoryStatement',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'cardClassId': this.props.cardsCalssData.id,
                'dataType': pullCard == 1 ? 2 : this.state.dataType,
                'dateFrom': this.state.dateFrom,
                'dateTo': this.state.dateTo,
                'page': this.state.activePage,
                'limit': this.state.limit,
                distId:this.state.distId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ errorMsg: data['errorMsg'], loading: 0 })
                } else {
                    this.setState({
                        data: data['inventoryStatement'],
                        dataCount: data['inventoryStatementCount'],
                        loading: 0
                    }, function () {
                        // if (pullCard == 1) {
                        //     // this.removeFile(fileName)
                        // }
                    })
                }
            }
        })
    }

    exportHandler() {
        if (this.state.dateFrom != '' && this.state.dateTo != '') {
            window.location = window.API_PERM_USER + "/permUser.php?type=" + "getInventoryStatement" + "&lang=" + localStorage.getItem('LANG') + "&userId=" + localStorage.getItem('USER_ID')
                + "&token=" + localStorage.getItem('USER_TOKEN') +
                "&cardClassId=" + this.props.cardsCalssData.id +
                '&dateFrom=' + this.state.dateFrom +
                '&dateTo=' + this.state.dateTo +"&distId="+this.state.distId+
                "&export=1"

        } else {
            this.setState({
                emptyFromDate: this.state.dateFrom == '' ? 1 : '',
                emptyToDate: this.state.dateTo == '' ? 1 : ''
            })
        }
    }
    handleClickOutside = (event) => {
        if (event.target.className != undefined) {
            if (!event.target.classList.contains('actionDropdownList')) {
                this.setState({
                    openHeader: ''
                })
            }
        }
    }
    openHeaderMenu = (event, id) => {
        event.preventDefault();
        if (event.target.classList[1] != undefined && event.target.classList[1] == "menu-dots-vertical" && id != undefined) {
            var val = ''
            if (this.state.openHeader == id) {
                val = ''
            } else if (id != undefined) {
                val = id
            }
            this.setState({
                openHeader: val
            })
        } else if (id == undefined && event.target.classList[1] != "menu-dots-vertical") {
            this.setState({
                openHeader: ''
            })
        }
    }
    handleCloseSideBar = (loading = 0) => {
        if (loading == 1) {
            this.setState({ openleftModal: false, loadingCardClass: loading })
        } else {
            this.setState({ openleftModal: false})
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getInventoryStatement();
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value,
            emptyFromDate: '',
            emptyToDate: '',
            activePage: 1
        }, function () {
            this.getInventoryStatement();
        })
    }
    openFromAction = (event, data, type) => {
        event.preventDefault();
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            dataRow: data,
            actionType: type,
            openleftModal: true
        }, function () {

        })
    }
    displayData() {
        var list = ''
        var cardCount = ''
        var type = this.state.dataType == 3 ? 1 : 0
        if (this.state.data.length > 0) {
            list = this.state.data.map((rowData, index) => {
                cardCount = rowData['type'] == 3 ? rowData['cardCount'][0]['cardCount'] : rowData['cardCount']
                return <Row key={index} className='table-body-row'>
                    <Col lg={type ? 3 : 6} md={type ? 3 : 6} sm={type ? 3 : 4} xs={12} className='main-color-1 font-w-400'>{rowData['cardEntryDate']}</Col>
                    <Col lg={type ? 3 : 4} md={type ? 3 : 4} sm={type ? 3 : 4} xs={12}>{cardCount}</Col>
                    <Col lg={type ? 3 : 2} md={type ? 3 : 2} sm={type ? 3 : 4} xs={12} className='text-center'>
                        {rowData['type'] == 1 ? Strings['Addition'] : rowData['type'] == 2 ? Strings['Withdraw'] : Strings['Order']}
                    </Col>
                    {this.state.dataType == 3 &&
                        <Col lg={3} md={3} sm={3} xs={12} className="action-icon-view actionDropdownList actionDropdownListPadding-0 text-md-right">
                            <Dropdown isOpen={(this.state.openHeader == rowData['orderId'] ? true : false)} className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist" >
                                <DropdownToggle tag="a" className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0" caret  >
                                    <MoreVertIcon className='menu-dots-vertical' onClick={(event) => this.openHeaderMenu(event, rowData['orderId'])} />
                                </DropdownToggle>
                                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList" >
                                    <div className="actionDropdownList" style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                                        <DropdownItem className='actionDropdownList' onClick={(event) => this.openFromAction(event, rowData, 'viewOrder')}>{Strings['Review Order']}</DropdownItem>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    }
                </Row>
            })
        }
        return list
    }
    closeModal = () => {
        this.props.handleCloseSideBar(this.state.loadingCardClass)
    }
    render() {
        var type = this.state.dataType == 3 ? 1 : 0
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col xs={10} lg={10} md={10} sm={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>
                                {Strings['Account Statement']}
                                ({localStorage.getItem('LANG') == 1 ? this.props.cardsCalssData.name : this.props.cardsCalssData.name_en})
                            </div>
                        </Col>
                        <Col md={2} xs={2} lg={2} sm={2} className='text-right'>
                            <HighlightOffIcon onClick={this.closeModal} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>
                <div className='sidebar-body-view sidebar-body-view-supplier-transaction'>
                    <Row className="page-filter-view">
                        <Col xl={{ size: 5, order: 1 }} lg={{ size: 7, order: 1, }} md={{ size: 7, order: 1, }} sm={{ size: 10, order: 1, }} xs={{ size: 12, order: 12, }}>
                            <AvForm >
                                <Row className='filter-form'>
                                    < Col md={10} xs={12} lg={10} sm={12}>
                                        <div className='member-searchable-list mb-2'>
                                            <AvField type="select" name="dataType" value={this.state.dataType} onChange={(event) => this.handleInputChange(event)}  >
                                                {<option value="1" >{Strings['Addition']}</option>}
                                                {<option value="2" >{Strings['Withdraw']}</option>}
                                                {(localStorage.getItem('USER_LEVEL') == 1 || localStorage.getItem('INV_MGR') == 1) &&
                                                    <option value="3" >{Strings['Order']}</option>
                                                }
                                            </AvField>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={2} md={2} sm={12}>
                                        <Button variant="contained" type="button" className='refresh-action-btn mb-2 mb-md-0' onClick={this.handleRefresh} title={Strings['Refresh']}>
                                            <i className='flaticon-refresh reset-filter-btn'></i>
                                        </Button>
                                    </Col>
                                    <Col md={6} xs={12} lg={6} sm={12} className='mb-1'>
                                        <AvField name="dateFrom" type='date' label={Strings['From Date']} value={this.state.dateFrom} onChange={(event) => this.handleInputChange(event)} className={this.state.emptyFromDate == 1 && 'filter-empty-email'} />
                                    </Col>
                                    <Col md={6} xs={12} lg={6} sm={12} className='mb-1'>
                                        <AvField name="dateTo" type='date' label={Strings['To Date']} value={this.state.dateTo} onChange={(event) => this.handleInputChange(event)} className={this.state.emptyToDate == 1 && 'filter-empty-email'} />
                                    </Col>
                                </Row >
                            </AvForm>
                        </Col>
                        {/* onClick={(event) => this.openSidebarModal(event, 'add', '')} */}
                        <Col xl={{ size: 7, order: 1 }} lg={{ size: 5, order: 1 }} md={{ size: 5, order: 1 }} sm={{ size: 10, order: 1 }} xs={{ size: 12, order: 12 }} className='text-right'>
                            {(localStorage.getItem('USER_LEVEL') == 1) &&
                                <button className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1" onClick={(event) => this.openFromAction(event, this.props.cardsCalssData, 'pullCards')}>
                                    {Strings['Pull Cards']}
                                </button>
                            }
                            <button className="btn page-action-btn ml-1 mt-2 mt-md-0" onClick={(event) => this.exportHandler(event)}>
                                {Strings['Export All Type']}
                            </button>
                        </Col>
                    </Row>
                    {this.state.loading == 1 &&
                        <Row>
                            <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                                <div className='card-body'>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                </div>
                            </Col>
                        </Row>
                    }

                    {this.state.loading == 0 && this.state.errorMsg != '' &&
                        <div className='text-center' style={{ marginTop: "15%" }}>
                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                            <div className='no-data-text'>{this.state.errorMsg}</div>
                        </div>
                    }

                    {this.state.loading == 0 && this.state.data.length <= 0 &&
                        <div className='text-center' style={{ marginTop: "15%" }}>
                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                            <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                        </div>
                    }
                    {this.state.loading == 0 && this.state.data.length > 0 &&
                        <Row>
                            <div className='inv-cards-view width-100'>
                                <Row className='table-header-row'>
                                    <Col lg={type ? 3 : 6} md={type ? 3 : 6} sm={type ? 3 : 4} xs={12}>{Strings['Date']}</Col>
                                    <Col lg={type ? 3 : 4} md={type ? 3 : 4} sm={type ? 3 : 4} xs={12}>{Strings['Count']}</Col>
                                    <Col lg={type ? 3 : 2} md={type ? 3 : 2} sm={type ? 3 : 4} xs={12} className='text-center'>{Strings['Type']}</Col>
                                    {this.state.dataType == 3 &&
                                        <Col lg={3} md={3} sm={3} xs={12} className='text-md-right'>{Strings['Action']}</Col>
                                    }
                                </Row>
                                {this.displayData()}
                                <Col md={12}>
                                    <Row className='pagenation-view mt-3'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={(this.state.limit)}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={4}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next' : 'flaticon2-back'}></i>}
                                            nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back' : 'flaticon2-next'}></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Row>
                                </Col>
                            </div>

                        </Row>
                    }
                </div>
                <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModal} className={'sidebar-modal-view'}>
                    {this.state.actionType == 'viewOrder' &&
                        <ViewOrder
                            viewPage="accountStatment"
                            orderRowData={this.state.dataRow}
                            handleCloseSideBar={this.handleCloseSideBar}
                        />
                    }
                    {this.state.actionType == 'pullCards' &&
                        <PullCards
                            cardsCalssData={this.state.dataRow}
                            handleCloseSideBar={this.handleCloseSideBar}
                            recallGetData={this.getInventoryStatement}
                        />
                    }
                </Drawer>
            </div>
        )
    }
}
export default AccountStatement;