import { translations } from "../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var settings_SumMenu = [];
var report_SumMenu = [];
var type_mega = "";
var width_mega = "";
if (
  (localStorage.getItem("ACCOUNT_TYPE") == "" ||
    localStorage.getItem("ACCOUNT_TYPE") == undefined ||
    localStorage.getItem("ACCOUNT_TYPE") == null) &&
  localStorage.getItem("USER_LEVEL") == 1
) {
  type_mega = "mega";
  width_mega = "max-content";
  settings_SumMenu = {
    type: "mega",
    width: "max-content",
    columns: [
      {
        heading: {
          title: Strings["General"],
        },
        // moreWidth: 1,
        items: [
          {
            title: Strings["Currency"],
            bullet: "dot",
            page: "settings/currency/currency",
            display:
              ((localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
                localStorage.getItem("USER_LEVEL") == 1) ||
              localStorage.getItem("USER_LEVEL") == 10
                ? "1"
                : "0",
          },
          {
            title: Strings["Attachments"],
            bullet: "dot",
            page: "settings/identification/identification",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Regions"],
            bullet: "dot",
            page: "settings/regions/regions",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Suppliers"],
            bullet: "dot",
            page: "settings/suppliers",
            display:
              localStorage.getItem("PERM_TYPE") == 1 ||
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Banks"],
            bullet: "dot",
            page: "settings/banks/banks",
            display:
              localStorage.getItem("PERM_TYPE") == 1 ||
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Purchase Orders"],
            bullet: "dot",
            page: "settings/purchaseOrders",
            display:
              localStorage.getItem("PERM_TYPE") == 1 ||
              localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("USER_LEVEL") == 16
                ? "1"
                : "0",
          },

          {
            title: Strings["Ding Countries"],
            bullet: "dot",
            page: "settings/dingCountries",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Trash"],
            bullet: "dot",
            page: "settings/trash",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Account Information"],
            bullet: "dot",
            long: 1,
            page: "settings/accountInfo/accountInfo",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },

          {
            title: Strings["Direct Charge Countries"],
            bullet: "dot",
            long: 1,
            page: "settings/directChargeCountry/directChargeCountry",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["General Settings"],
            bullet: "dot",
            long: 1,
            page: "settings/supplierBlockTime",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Supplier Cards Held In Buffer"],
            bullet: "dot",
            long: 1,
            page: "settings/unlockSupplyerBuffer",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["API Suppliers For The Class"],
            bullet: "dot",
            long: 1,
            page: "settings/supplierApiOfClass/supplierApiOfClass",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Suppliers Prices"],
            bullet: "dot",
            long: 1,
            page: "settings/supplierPrice",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Get Codes By Send Email"],
            bullet: "dot",
            page: "settings/GetCodesBySendEmail",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            // baraa remove comment here
            title: Strings["Serials Information"],
            bullet: "dot",
            page: "settings/SerialsInformation",
            display:
              ((localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
                localStorage.getItem("USER_LEVEL") == 1) ||
              localStorage.getItem("USER_LEVEL") == 14
                ? "1"
                : "0",
          },
          {
            // baraa remove comment here
            title: Strings["QR Code Types"],
            bullet: "dot",
            page: "settings/QRCodeTypes",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
        ],
      },
      {
        heading: {
          title: Strings["Distributors"],
        },
        moreWidth: 1,
        items: [
          {
            title: Strings["Conversion Reasons"],
            bullet: "dot",
            page: "settings/conversionReasons",
            display: "1",
          },
          {
            title: Strings["Commission List"],
            bullet: "dot",
            long: "1",
            page: "settings/efawateerComDist",
            display: "1",
          },
          {
            title: Strings["Invoice Information"],
            bullet: "dot",
            page: "settings/invoiceInfo",
            display: "1",
          },
          {
            title: Strings["Inquiries about POS"],
            bullet: "dot",
            page: "settings/posSearch",
            display: "1",
          },
          {
            title: Strings["Balance Recharge Requests"],
            bullet: "dot",
            long: 1,
            page: "settings/paymentRequest/paymentRequest", //localStorage.getItem('PERM_TYPE') == 1 ||localStorage.getItem('PERM_TYPE') == 3 ||localStorage.getItem('PERM_TYPE') == 2 ||
            display: "1",
          },
          {
            title: Strings["Account Statement Dist And Pos"],
            bullet: "dot",
            long: 1,
            page: "settings/accountStatementDistAndPos",
            display: "1",
          },
          {
            title: Strings["Financial operations for all distributors"],
            bullet: "dot",
            long: "1",
            page: "settings/allDistTrans",
            display: "1",
          },
          {
            title: Strings["Activate Distributors Cards"],
            bullet: "dot",
            page: "settings/activateDistsCards",
            display: "1",
          },
          {
            title: Strings["Distributors Warehouse"],
            bullet: "dot",
            page: "settings/distsInventory",
            display: "1",
          },
          {
            title: Strings["Distributors Classes"],
            bullet: "dot",
            page: "settings/distCategory/distCategory",
            display: "1",
          },
          {
            title: Strings["Distributor Cards Prices"],
            bullet: "dot",
            page: "settings/distCardPrice/distCardPrice",
            display: "1",
          },
          {
            title: Strings["Incomplete Credit Additions"],
            long: 1,
            bullet: "dot",
            page: "settings/faildAddBalanceReport/faildAddBalanceReport",
            display: "1",
          },
          {
            title: Strings["Logs Requests API To Distributors"],
            bullet: "dot",
            long: 1,
            page:
              "settings/logsRequestsAPIToDistributors/logsRequestsAPIToDistributors",
            display: "1",
          },
          {
            title: Strings["Activate Tax Categories for Distributors"],
            bullet: "dot",
            long: 1,
            page: "settings/activateDistCatsTax",
            display: "1",
          },
          // {
          //   title: Strings["FireBase Report"],
          //   bullet: "dot",
          //   long: 1,
          //   page: "settings/firebasereport",
          //   display:
          //     (localStorage.getItem("ACCOUNT_TYPE") == "" ||
          //       localStorage.getItem("ACCOUNT_TYPE") == undefined ||
          //       localStorage.getItem("ACCOUNT_TYPE") == null) &&
          //     localStorage.getItem("USER_LEVEL") == 1,
          // },
        ],
      },
      {
        heading: {
          title: Strings["Cards"],
        },
        moreWidth: 1,
        items: [
          {
            title: Strings["Wholesale Orders"],
            bullet: "dot",
            page: "wholesaleOrders",
            display: "1",
          },
          // {
          //   title: Strings['PayThem Cards'],
          //   bullet: "dot",
          //   page: "settings/payThemCards",
          //   display: '1'
          // },
          // {
          //   title: Strings['Egifter Cards'],
          //   bullet: "dot",
          //   page: "settings/egifterCards",
          //   display: '1'
          // },
          // {
          //   title: Strings['PrePaidForge Cards'],
          //   bullet: "dot",
          //   page: "settings/prePaidForgeCards",
          //   display: '1'
          // },
          // {
          //   title: Strings['OneCard Cards'],
          //   bullet: "dot",
          //   page: "settings/oneCardCards",
          //   display: '1'
          // },
          {
            title: Strings["Ding Cards"],
            bullet: "dot",
            page: "settings/dingCards",
            display: "1",
          },
          // BaraaHideTemp
          {
            title: Strings["AuditingPOS"],
            bullet: "dot",
            page: "settings/AuditingPOS",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          // baraaHideTemp end
          // {
          //   title: Strings['Ezpin Cards'],
          //   bullet: "dot",
          //   page: "settings/ezpinCards",
          //   display: '1'
          // },
          // {
          //   title: Strings['Joker Cards'],
          //   bullet: "dot",
          //   page: "settings/jokerCards",
          //   display: '1'
          // },
          // {
          //   title: Strings['Gift2Game Cards'],
          //   bullet: "dot",
          //   page: "settings/gift2gamesCards",
          //   display: '1'
          // },
          {
            title: Strings["API Suppliers Cards"],
            bullet: "dot",
            page: "settings/apiSuppliersCard",
            display: "1",
          },
          {
            title: Strings["Card Inquiry"],
            bullet: "dot",
            page: "settings/cardQuery",
            display: "1",
          },
          {
            title: Strings["Orders Processing"],
            bullet: "dot",
            page: "settings/orderErrorFixes",
            display: "1",
          },
          {
            title: Strings["Processing Reserved Cards"],
            bullet: "dot",
            long: 1,
            page: "settings/reservedCards/reservedCards",
            display: "1",
          },
          {
            title: Strings["Orders Notes"],
            bullet: "dot",
            page: "settings/orderNote/orderNote/0",
            display: "1",
          },
          {
            title: Strings["Reprint POS Orders"],
            bullet: "dot",
            page: "settings/orderNote/orderNote/1",
            display: "1",
          },
          {
            title: Strings["Incomplete Orders"],
            bullet: "dot",
            page: "settings/notCompleteOrder/notCompleteOrder",
            display: "1",
          },
          // This page will continue to build in the next build
          {
            title: Strings["Duplicate Cards"],
            bullet: "dot",
            page: "settings/doublictedCard/1",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Cards Transfer"],
            bullet: "dot",
            page: "settings/cardTransfer",
            display: "1",
          },
          {
            title: Strings["Deactivate Cards"],
            bullet: "dot",
            page: "settings/deactiveInv",
            display: "1",
          },
          {
            title: Strings["Orders Problems"],
            bullet: "dot",
            page: "settings/orderProblem/orderProblem",
            display: "1",
          },
        ],
      },
    ],
  };
  report_SumMenu = {
    type: "mega",
    width: "max-content",
    columns: [
      {
        heading: {
          title: Strings["Inventory"],
        },
        moreWidth: localStorage.getItem("LANG") == 1 ? 1 : 2,
        items: [
          {
            title: Strings["Cards Inventory Report"],
            bullet: "dot",
            page: "reports/cardsInventoryReport",
            display: "1",
          },
          {
            title: Strings["Cards Profit inventory report"],
            bullet: "dot",
            page: "reports/CardsProfitInventoryForAdmin",
            display: "1",
          },
          {
            title: Strings["Cards Profit inventory report"] + "-V2",
            bullet: "dot",
            page: "reports/cardsProfitInventoryReportV2",
            display: "1",
          },
          // baraa here one ID case localStorage.getItem('USER_ID')==165
          // baraaHide
          {
            title: Strings["Cards Profit inventory report"] + "-API",
            bullet: "dot",
            page: "reports/CardsProfitInventoryReportAPI",
            display: localStorage.getItem("USER_LEVEL") == 1,
          },

          {
            title: Strings["POS Cards Profit Inventory Report"],
            bullet: "dot",
            long: 1,
            page: "reports/posCardProfitInventoryAdmin",
            display: "1",
          },
          {
            title: Strings["Cards Profit Inventory Report With Bank Info"],
            bullet: "dot",
            long: 1,
            page: "reports/inventoryReportAndBankInfo",
            display: "1",
          },
          {
            title: Strings["Cards Transfer Report"],
            bullet: "dot",
            page: "reports/transferCard",
            display: "1",
          },
        ],
      },
      {
        heading: {
          title: Strings["General"],
        },
        moreWidth: 1,
        items: [
          {
            title: Strings["Invoice Report"],
            bullet: "dot",
            page: "reports/invoiceReport",
            display: "1",
          },
          {
            title: Strings["Withdrawal Invoices"],
            bullet: "dot",
            page: "reports/withdrawalInvoices",
            display: "1",
          },
          {
            title: Strings["Requests For Reports"],
            bullet: "dot",
            page: "reports/reportRequest",
            display: "1",
          },
          {
            title: Strings["Duplicate Orders Report"],
            bullet: "dot",
            page: "reports/dublicatedOrdersReport",
            display: "1",
          },
          {
            title: Strings["Repeat Cards Report"],
            bullet: "dot",
            page: "reports/repeatCardsReport",
            display: "1",
          },

          {
            title: Strings["Direct Shipping Report"],
            bullet: "dot",
            page: "reports/directChargeLogs",
            display: "1",
          },
          {
            title: Strings["Cron Job Report"],
            bullet: "dot",
            page: "reports/cronJob",
            display: "1",
          },
        ],
      },
    ],
  };
} else {
  settings_SumMenu = [
    {
      title: Strings["Purchase Orders"],
      bullet: "dot",
      page: "settings/purchaseOrders",
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("INV_MGR") == 1 ||
        localStorage.getItem("USER_LEVEL") == 16
          ? "1"
          : "0",
    },
    {
      title: Strings["Suppliers"],
      bullet: "dot",
      page: "settings/suppliers",
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("INV_MGR") == 1
          ? "1"
          : "0",
    },
    {
      title: Strings["Banks"],
      bullet: "dot",
      page: "settings/banks/banks",
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("INV_MGR") == 1
          ? "1"
          : "0",
    },
    {
      title: Strings["Balance Recharge Requests"],
      bullet: "dot",
      page: "settings/paymentRequest/paymentRequest", //localStorage.getItem('PERM_TYPE') == 1 ||localStorage.getItem('PERM_TYPE') == 3 ||localStorage.getItem('PERM_TYPE') == 2 ||
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("PERM_TYPE") == 3 ||
        localStorage.getItem("PERM_TYPE") == 2
          ? "1"
          : "0",
    },
    {
      title: Strings["Card Inquiry"],
      bullet: "dot",
      page: "settings/cardQuery",
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("INV_MGR") == 1
          ? "1"
          : "0",
    },
    {
      title: Strings["Cards Prices"],
      bullet: "dot",
      page: "settings/distCardPrice/distCardPrice",
      display: localStorage.getItem("USER_LEVEL") == 2 ? "1" : "0",
    },
    {
      title: Strings["Orders Problems"],
      bullet: "dot",
      page: "settings/orderProblem/orderProblem",
      display:
        localStorage.getItem("USER_LEVEL") == 2 ||
        localStorage.getItem("USER_LEVEL") == 15 ||
        // baraaHide
        localStorage.getItem("USER_LEVEL") == 14 ||
        (localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 1)
          ? "1"
          : "0",
    },
    {
      title: Strings["Activate Tax Categories"],
      bullet: "dot",
      page: "settings/activateDistCatsTax",
      display:
        localStorage.getItem("PERM_TYPE") == 5 &&
        localStorage.getItem("IS_TAXDIST") == 1
          ? "1"
          : "0",
    },
    // This page will continue to build in the next build
    // {
    //   title: Strings['Duplicate Cards'],
    //   bullet: "dot",
    //   page: "settings/doublictedCard",
    //   display: ((localStorage.getItem('ACCOUNT_TYPE') == '' || localStorage.getItem('ACCOUNT_TYPE') == undefined || localStorage.getItem('ACCOUNT_TYPE') == null) && localStorage.getItem('USER_LEVEL') == 1) ? "1" : "0"
    // },
  ];
  report_SumMenu = [
    {
      title: Strings["POS Cards Profit"],
      bullet: "dot",
      page: "reports/posCardsProfit",
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("USER_LEVEL") == 10
          ? "1"
          : "0",
    },
    {
      title: Strings["Requests For Reports"],
      bullet: "dot",
      page: "reports/reportRequest",
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("USER_LEVEL") == 10
          ? "1"
          : "0",
    },
    {
      title: Strings["Financial Operations Report"],
      bullet: "dot",
      page: "reports/financialOperations",
      display:
        localStorage.getItem("PERM_TYPE") == 2 ||
        localStorage.getItem("PERM_TYPE") == 3 ||
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("POS_MGR_EMP") == 1 ||
        localStorage.getItem("ACC_EMP") == 1
          ? "1"
          : "0",
    },
    {
      title: Strings["Account Statement"],
      bullet: "dot",
      page: "reports/accountStatement",
      display:
        localStorage.getItem("PERM_TYPE") == 2 ||
        localStorage.getItem("PERM_TYPE") == 3 ||
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("POS_MGR_EMP") == 1 ||
        localStorage.getItem("ACC_EMP") == 1
          ? "1"
          : "0",
    },
    {
      title: Strings["Cards Sales"],
      bullet: "dot",
      page: "reports/cardsProfitInventory",
      display:
        localStorage.getItem("PERM_TYPE") == 2 ||
        localStorage.getItem("PERM_TYPE") == 3
          ? "1"
          : "0",
    },
    {
      title: Strings["Sales Account Report"],
      bullet: "dot",
      page: "reports/salesAccount",
      display: localStorage.getItem("PERM_TYPE") == 4 ? "1" : "0",
    },
    {
      title: Strings["Cards Profit inventory report"],
      bullet: "dot",
      page: "reports/CardsProfitInventoryForAdmin",
      display:
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("USER_LEVEL") == 10
          ? "1"
          : "0",
    },
    // baraa added another hide for this report
    {
      title: Strings["Cards Profit inventory report"] + "-V3",
      bullet: "dot",
      page: "reports/cardsProfitInventoryReportV3",
      // display: localStorage.getItem("PERM_TYPE") == 1 ? "1" : "0",
      display: localStorage.getItem("USER_ID") == 165 ? "1" : "0",
    },
    {
      title: Strings["Cards Inventory Report"],
      bullet: "dot",
      page: "reports/cardsInventoryReport",
      display:
        localStorage.getItem("PERM_TYPE") == 1 ||
        localStorage.getItem("USER_LEVEL") == 10
          ? "1"
          : "0",
    },
    // {
    //   title: Strings['Dashboard'],
    //   bullet: "dot",
    //   page: "reports/reportDashboard",
    //   display: localStorage.getItem('PERM_TYPE') == 2 ? '1' : '0'
    // }
  ];
}
export default {
  header: {
    self: {},
    items: [
      {
        title: Strings["Dashboard"],
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        display:
          (localStorage.getItem("ACCOUNT_TYPE") == 9 &&
            localStorage.getItem("USER_LEVEL") == 9) ||
          localStorage.getItem("USER_LEVEL") == 16
            ? "0"
            : "1",
      },
      {
        title: Strings["Account Information"],
        root: true,
        alignment: "left",
        page: "AccountSettingsPOS",
        translate: "MENU.DASHBOARD",
        display:
          localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 7
            ? "1"
            : "0",
      },
      {
        title: Strings["My Account"],
        // title: Strings['Distributors'],
        root: true,
        alignment: "left",
        page: "distributors",
        translate: "MENU.DASHBOARD",
        display:
          localStorage.getItem("PERM_TYPE") == 1 ||
          localStorage.getItem("PERM_TYPE") == 4 ||
          localStorage.getItem("PERM_TYPE") == 5
            ? "1"
            : "0",
      },
      {
        title: Strings["Points Of Sales"],
        root: true,
        alignment: "left",
        page:
          localStorage.getItem("ACCOUNT_TYPE") == 11 &&
          localStorage.getItem("USER_LEVEL") == 11
            ? "daftraEmp"
            : "posEmp",
        translate: "MENU.DASHBOARD",
        display:
          localStorage.getItem("PERM_TYPE") == 1 ||
          localStorage.getItem("PERM_TYPE") == 4 ||
          localStorage.getItem("PERM_TYPE") == 5 ||
          ((localStorage.getItem("OPERA_EMP") == 1 ||
            localStorage.getItem("ACC_EMP") == 1) &&
            localStorage.getItem("PERM_TYPE_PARENT") == 5) ||
          (localStorage.getItem("ACCOUNT_TYPE") == 11 &&
            localStorage.getItem("USER_LEVEL") == 11) ||
          (localStorage.getItem("ACCOUNT_TYPE") == 8 &&
            localStorage.getItem("USER_LEVEL") == 8)
            ? "1"
            : "0",
      },
      // {
      //   title: Strings['Clients'],
      //   root: true,
      //   alignment: "left",
      //   page: "clients",
      //   translate: "MENU.DASHBOARD"
      // },
      {
        title: Strings["Buy Cards"],
        root: true,
        alignment: "left",
        page:
          localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 7
            ? "POSbuyCards"
            : "buyCards",
        translate: "MENU.DASHBOARD",
        display:
          localStorage.getItem("PERM_TYPE") == 3 ||
          (localStorage.getItem("USER_LEVEL") == 3 &&
            localStorage.getItem("ACCOUNT_TYPE") == 7)
            ? "1"
            : "0",
      },
      {
        title: Strings["Inventory"],
        root: true,
        alignment: "left",
        page:
          localStorage.getItem("ACCOUNT_TYPE") == 11 &&
          localStorage.getItem("USER_LEVEL") == 11
            ? "daftraInventory"
            : "inventory", //(localStorage.getItem('ACCOUNT_TYPE') == '' && localStorage.getItem('USER_LEVEL') == 1) ||
        translate: "MENU.DASHBOARD",
        display:
          (localStorage.getItem("ACCOUNT_TYPE") == 9 &&
            localStorage.getItem("USER_LEVEL") == 9) ||
          localStorage.getItem("PERM_TYPE") == 5 ||
          localStorage.getItem("OPERA_EMP") == 1 ||
          localStorage.getItem("ACC_EMP") == 1 ||
          (localStorage.getItem("ACCOUNT_TYPE") == 8 &&
            localStorage.getItem("USER_LEVEL") == 8) ||
          // baraaHideTemp
          localStorage.getItem("USER_LEVEL") == 14 ||
          localStorage.getItem("USER_LEVEL") == 16 ||
          localStorage.getItem("USER_LEVEL") == 3
            ? // baraaHideTemp
              "0"
            : "1",
      },

      {
        title: Strings["Distributors"],
        root: true,
        page: "settings/admin/Distributors",
        display:
          ((localStorage.getItem("ACCOUNT_TYPE") == "" ||
            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
            localStorage.getItem("ACCOUNT_TYPE") == null) &&
            localStorage.getItem("USER_LEVEL") == 1) ||
          localStorage.getItem("USER_LEVEL") == 14 ||
          localStorage.getItem("USER_LEVEL") == 10
            ? "1"
            : "0",
      },
      // baraaHideTemp
      {
        title: Strings["Tools"],
        root: true,
        alignment: "left",
        page: "tools",
        translate: "MENU.DASHBOARD",
        display: localStorage.getItem("PERM_TYPE") == 4 ? "1" : "0",
      },
      // {
      //   title: Strings['Wholesale Orders'],
      //   root: true,
      //   alignment: "left",
      //   page: "wholesaleOrders",
      //   translate: "MENU.DASHBOARD",
      //   display: ((localStorage.getItem('ACCOUNT_TYPE') == '' || localStorage.getItem('ACCOUNT_TYPE') == undefined || localStorage.getItem('ACCOUNT_TYPE') == null) && localStorage.getItem('USER_LEVEL') == 1) ? '1' : '0',
      // },
      {
        title: Strings["Permissions"],
        root: true,
        alignment: "left",
        toggle: "click",
        display:
          (localStorage.getItem("ACCOUNT_TYPE") == "" ||
            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
            localStorage.getItem("ACCOUNT_TYPE") == null) &&
          localStorage.getItem("USER_LEVEL") == 1
            ? "1"
            : "0",
        submenu: [
          {
            title: Strings["Pages Permissions"],
            bullet: "dot",
            page: "permissions/permissionsPage",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
          {
            title: Strings["Permission Types"],
            bullet: "dot",
            page: "permissions/permissionsTypes",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },
        ],
      },
      {
        title: Strings["Settings"],
        root: true,
        alignment: "right",
        type: type_mega,
        width: width_mega,
        toggle: "click", //localStorage.getItem('PERM_TYPE') == 3 ||localStorage.getItem('PERM_TYPE') == 2 ||
        display:
          ((localStorage.getItem("ACCOUNT_TYPE") == "" ||
            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
            localStorage.getItem("ACCOUNT_TYPE") == null) &&
            localStorage.getItem("USER_LEVEL") == 1) ||
          localStorage.getItem("PERM_TYPE") == 3 ||
          localStorage.getItem("PERM_TYPE") == 2 ||
          localStorage.getItem("PERM_TYPE") == 1 ||
          localStorage.getItem("PERM_TYPE") == 5 ||
          localStorage.getItem("INV_MGR") == 1 ||
          localStorage.getItem("USER_LEVEL") == 15 ||
          // baraaHideTemp
          localStorage.getItem("USER_LEVEL") == 14 ||
          (localStorage.getItem("USER_LEVEL") == 3 &&
            localStorage.getItem("ACCOUNT_TYPE") == 1) ||
          localStorage.getItem("USER_LEVEL") == 16 ||
          localStorage.getItem("USER_LEVEL") == 10
            ? "1"
            : "0",
        submenu:
          localStorage.getItem("USER_LEVEL") == 14
            ? settings_SumMenu?.push(
                {
                  title: Strings["Financial operations for all distributors"],
                  bullet: "dot",
                  long: "1",
                  page: "settings/allDistTrans",
                  display: localStorage.getItem("USER_LEVEL") == 14 ? "1" : "0",
                },
                {
                  title: Strings["Account Information"],
                  bullet: "dot",
                  long: 1,
                  page: "settings/accountInfo/accountInfo",
                  display: localStorage.getItem("USER_LEVEL") == 14 ? "1" : "0",
                },
                {
                  title: Strings["Card Inquiry"],
                  bullet: "dot",
                  page: "settings/cardQuery",
                  display: localStorage.getItem("USER_LEVEL") == 14 ? "1" : "0",
                },
                {
                  title: Strings["Incomplete Credit Additions"],
                  long: 1,
                  bullet: "dot",
                  page: "settings/faildAddBalanceReport/faildAddBalanceReport",
                  display: localStorage.getItem("USER_LEVEL") == 14 ? "1" : "0",
                },
                {
                  // baraa remove comment here
                  title: Strings["Serials Information"],
                  bullet: "dot",
                  page: "settings/SerialsInformation",
                  display: localStorage.getItem("USER_LEVEL") == 14 ? "1" : "0",
                },
                {
                  title: Strings["Balance Recharge Requests"],
                  bullet: "dot",
                  long: 1,
                  page: "settings/paymentRequest/paymentRequest", //localStorage.getItem('PERM_TYPE') == 1 ||localStorage.getItem('PERM_TYPE') == 3 ||localStorage.getItem('PERM_TYPE') == 2 ||
                  display: localStorage.getItem("USER_LEVEL") == 14 ? "1" : "0",
                }
              ) && settings_SumMenu
            : localStorage.getItem("USER_LEVEL") == 10
            ? (settings_SumMenu = {
                type: "mega",
                width: "max-content",
                columns: [
                  {
                    heading: {
                      title: Strings["General"],
                    },
                    // moreWidth: 1,
                    items: [
                      {
                        title: Strings["Currency"],
                        bullet: "dot",
                        page: "settings/currency/currency",
                        display:
                          ((localStorage.getItem("ACCOUNT_TYPE") == "" ||
                            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                            localStorage.getItem("ACCOUNT_TYPE") == null) &&
                            localStorage.getItem("USER_LEVEL") == 1) ||
                          localStorage.getItem("USER_LEVEL") == 10
                            ? "1"
                            : "0",
                      },
                      {
                        title: Strings["Regions"],
                        bullet: "dot",
                        page: "settings/regions/regions",
                        display:
                          (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                            localStorage.getItem("ACCOUNT_TYPE") == null) &&
                          localStorage.getItem("USER_LEVEL") == 1
                            ? "1"
                            : "0",
                      },
                      {
                        title: Strings["Suppliers"],
                        bullet: "dot",
                        page: "settings/suppliers",
                        display:
                          localStorage.getItem("PERM_TYPE") == 1 ||
                          localStorage.getItem("USER_LEVEL") == 1
                            ? "1"
                            : "0",
                      },
                      {
                        title: Strings["Purchase Orders"],
                        bullet: "dot",
                        page: "settings/purchaseOrders",
                        display:
                          localStorage.getItem("PERM_TYPE") == 1 ||
                          localStorage.getItem("USER_LEVEL") == 1
                            ? "1"
                            : "0",
                      },
                      {
                        title: Strings["Account Information"],
                        bullet: "dot",
                        long: 1,
                        page: "settings/accountInfo/accountInfo",
                        display:
                          (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                            localStorage.getItem("ACCOUNT_TYPE") == null) &&
                          localStorage.getItem("USER_LEVEL") == 1
                            ? "1"
                            : "0",
                      },
                      {
                        title: Strings["General Settings"],
                        bullet: "dot",
                        long: 1,
                        page: "settings/supplierBlockTime",
                        display:
                          (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                            localStorage.getItem("ACCOUNT_TYPE") == null) &&
                          localStorage.getItem("USER_LEVEL") == 1
                            ? "1"
                            : "0",
                      },
                    ],
                  },
                  {
                    heading: {
                      title: Strings["Distributors"],
                    },
                    moreWidth: 1,
                    items: [
                      {
                        title: Strings["Invoice Information"],
                        bullet: "dot",
                        page: "settings/invoiceInfo",
                        display: "1",
                      },
                      {
                        title: Strings["Balance Recharge Requests"],
                        bullet: "dot",
                        long: 1,
                        page: "settings/paymentRequest/paymentRequest", //localStorage.getItem('PERM_TYPE') == 1 ||localStorage.getItem('PERM_TYPE') == 3 ||localStorage.getItem('PERM_TYPE') == 2 ||
                        display: "1",
                      },
                      {
                        title: Strings["Account Statement Dist And Pos"],
                        bullet: "dot",
                        long: 1,
                        page: "settings/accountStatementDistAndPos",
                        display: "1",
                      },
                      {
                        title: Strings["Activate Distributors Cards"],
                        bullet: "dot",
                        page: "settings/activateDistsCards",
                        display: "1",
                      },
                      {
                        title: Strings["Distributors Warehouse"],
                        bullet: "dot",
                        page: "settings/distsInventory",
                        display: "1",
                      },
                      {
                        title: Strings["Distributor Cards Prices"],
                        bullet: "dot",
                        page: "settings/distCardPrice/distCardPrice",
                        display: "1",
                      },
                    ],
                  },
                  {
                    heading: {
                      title: Strings["Cards"],
                    },
                    moreWidth: 1,
                    items: [
                      {
                        title: Strings["Wholesale Orders"],
                        bullet: "dot",
                        page: "wholesaleOrders",
                        display: "1",
                      },
                      // {
                      //   title: Strings['PayThem Cards'],
                      //   bullet: "dot",
                      //   page: "settings/payThemCards",
                      //   display: '1'
                      // },
                      // {
                      //   title: Strings['Egifter Cards'],
                      //   bullet: "dot",
                      //   page: "settings/egifterCards",
                      //   display: '1'
                      // },
                      // {
                      //   title: Strings['PrePaidForge Cards'],
                      //   bullet: "dot",
                      //   page: "settings/prePaidForgeCards",
                      //   display: '1'
                      // },
                      // {
                      //   title: Strings['OneCard Cards'],
                      //   bullet: "dot",
                      //   page: "settings/oneCardCards",
                      //   display: '1'
                      // },

                      // BaraaHideTemp

                      // baraaHideTemp end
                      // {
                      //   title: Strings['Ezpin Cards'],
                      //   bullet: "dot",
                      //   page: "settings/ezpinCards",
                      //   display: '1'
                      // },
                      // {
                      //   title: Strings['Joker Cards'],
                      //   bullet: "dot",
                      //   page: "settings/jokerCards",
                      //   display: '1'
                      // },
                      // {
                      //   title: Strings['Gift2Game Cards'],
                      //   bullet: "dot",
                      //   page: "settings/gift2gamesCards",
                      //   display: '1'
                      // },
                      {
                        title: Strings["Card Inquiry"],
                        bullet: "dot",
                        page: "settings/cardQuery",
                        display: "1",
                      },
                    ],
                  },
                ],
              })
            : settings_SumMenu,
      },
      {
        title: Strings["Reports"],
        root: true,
        alignment: "right",
        type: type_mega,
        width: width_mega,
        toggle: "click", //
        // display: (localStorage.getItem('PERM_TYPE') == 4 || localStorage.getItem('PERM_TYPE') == 2 || localStorage.getItem('PERM_TYPE') == 3 || (localStorage.getItem('ACCOUNT_TYPE') == 2 && localStorage.getItem('USER_LEVEL') == 3)) ? '1' : '0',
        // baraaHideTemp
        display: localStorage.getItem("USER_LEVEL") == 14 ? "1" : "0",

        submenu: [
          {
            title: Strings["Direct Shipping Report"],
            bullet: "dot",
            page: "reports/directChargeLogs",
            display: "1",
          },
        ],
      },
      {
        title: Strings["Reports"],
        root: true,
        alignment: "right",
        type: type_mega,
        width: width_mega,
        toggle: "click", //
        // display: (localStorage.getItem('PERM_TYPE') == 4 || localStorage.getItem('PERM_TYPE') == 2 || localStorage.getItem('PERM_TYPE') == 3 || (localStorage.getItem('ACCOUNT_TYPE') == 2 && localStorage.getItem('USER_LEVEL') == 3)) ? '1' : '0',
        display:
          localStorage.getItem("USER_LEVEL") == 1 ||
          localStorage.getItem("PERM_TYPE") == 1 ||
          localStorage.getItem("PERM_TYPE") == 4 ||
          localStorage.getItem("PERM_TYPE") == 2 ||
          localStorage.getItem("PERM_TYPE") == 3 ||
          localStorage.getItem("PERM_TYPE") == 5 ||
          localStorage.getItem("POS_MGR_EMP") == 1 ||
          localStorage.getItem("ACC_EMP") == 1 ||
          localStorage.getItem("USER_LEVEL") == 10
            ? "1"
            : "0",

        submenu: report_SumMenu,
      },
      {
        title: Strings["Financial operations"],
        root: true,
        alignment: "left",
        toggle: "click",
        display:
          localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 7
            ? "1"
            : "0",
        submenu: [
          {
            title: Strings["Financial Operations Report"],
            bullet: "dot",
            page: "reports/posTransactionsLog",
            display:
              localStorage.getItem("USER_LEVEL") == 3 &&
              localStorage.getItem("ACCOUNT_TYPE") == 7
                ? "1"
                : "0",
          },
        ],
      },
      {
        title: Strings["Invoices"],
        root: true,
        alignment: "left",
        toggle: "click",
        display:
          localStorage.getItem("PERM_TYPE") == 3 ||
          localStorage.getItem("PERM_TYPE") == 2
            ? "1"
            : "0",
        submenu: [
          {
            title: Strings["Balance Recharge Invoice"],
            bullet: "dot",
            page: "invoices/balanceRechargeInvoice",
            display:
              localStorage.getItem("PERM_TYPE") == 3 ||
              localStorage.getItem("PERM_TYPE") == 2
                ? "1"
                : "0",
          },
          {
            title: Strings["Withdrawal Invoices"],
            bullet: "dot",
            page: "invoices/withdrawalInvoices",
            display:
              localStorage.getItem("PERM_TYPE") == 3 ||
              localStorage.getItem("PERM_TYPE") == 2
                ? "1"
                : "0",
          },
        ],
      },
      {
        title: Strings["Administration"],
        root: true,
        alignment: "left",
        page: "accounts/accountBalances",
        translate: "MENU.DASHBOARD",
        toggle: "click",
        display:
          (localStorage.getItem("ACCOUNT_TYPE") == "" ||
            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
            localStorage.getItem("ACCOUNT_TYPE") == null) &&
          localStorage.getItem("USER_LEVEL") == 1 &&
          localStorage.getItem("ACCOUNT_TYPE") != undefined &&
          localStorage.getItem("ACCOUNT_TYPE") != null &&
          (localStorage
            .getItem("PERM_API_PAGES")
            .includes("account_report_balance") ||
            localStorage
              .getItem("PERM_API_PAGES")
              .includes("report_balanceBal"))
            ? "1"
            : "0",
        submenu: [
          {
            title: Strings["Account Balances"],
            bullet: "dot",
            page: "accounts/accountBalances",
            display:
              localStorage.getItem("USER_LEVEL") == 1 &&
              localStorage
                .getItem("PERM_API_PAGES")
                .includes("account_report_balance")
                ? "1"
                : "0",
          },
          {
            title: Strings["Account Balances Report"],
            bullet: "dot",
            page: "accounts/accountBalanceReport",
            display:
              localStorage.getItem("USER_LEVEL") == 1 &&
              localStorage
                .getItem("PERM_API_PAGES")
                .includes("report_balanceBal")
                ? "1"
                : "0",
          },
          {
            title: Strings["Managers List"],
            bullet: "dot",
            page: "settings/admin/adminList",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
                ? "1"
                : "0",
          },

          {
            title: Strings["Cards Profit inventory report"] + "-V3",
            bullet: "dot",
            page: "reports/cardsProfitInventoryReportV3",
            display: localStorage.getItem("USER_LEVEL") == 1 ? "1" : "0",
          },

          {
            title: Strings["Move copy from the repository"],
            bullet: "dot",
            long: 1,
            page: "settings/copyInv",
            display:
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1 &&
              localStorage.getItem("iS_MAIN_ADMIN") == 1
                ? "1"
                : "0",
          },
        ],
      },
      {
        title: Strings["DTone Configrations"],
        root: true,
        alignment: "left",
        page: "dtoneConfig/dtoneCountries",
        translate: "MENU.DASHBOARD",
        toggle: "click",
        display:
          (localStorage.getItem("ACCOUNT_TYPE") == "" ||
            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
            localStorage.getItem("ACCOUNT_TYPE") == null) &&
          localStorage.getItem("USER_LEVEL") == 1 &&
          localStorage.getItem("ACCOUNT_TYPE") != undefined &&
          localStorage.getItem("ACCOUNT_TYPE") != null
            ? "1"
            : "0",
        submenu: [
          {
            title: Strings["DTone Countries"],
            bullet: "dot",
            page: "dtoneConfig/dtoneCountries",
            display: localStorage.getItem("USER_LEVEL") == 1 ? "1" : "0",
          },
          {
            title: Strings["DTone Services"],
            bullet: "dot",
            page: "dtoneConfig/dtoneServices",
            display: localStorage.getItem("USER_LEVEL") == 1 ? "1" : "0",
          },
          {
            title: Strings["DTone Services Classes"],
            bullet: "dot",
            page: "dtoneConfig/dtoneServicesClasses",
            display: localStorage.getItem("USER_LEVEL") == 1 ? "1" : "0",
          },
          {
            title: Strings["DTone Providers"],
            bullet: "dot",
            page: "dtoneConfig/dtoneProviders",
            display: localStorage.getItem("USER_LEVEL") == 1 ? "1" : "0",
          },
          {
            title: Strings["DTone Products"],
            bullet: "dot",
            page: "dtoneConfig/dtoneProducts",
            display: localStorage.getItem("USER_LEVEL") == 1 ? "1" : "0",
          },
        ],
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: Strings["Points Of Sales Orders"],
        root: true,
        icon: "flaticon-app",
        ///Dania Edit add new type 11 in (20-01-2022)
        page:
          localStorage.getItem("ACCOUNT_TYPE") == 11 &&
          localStorage.getItem("USER_LEVEL") == 11
            ? "daftraOrder"
            : "ordersList",
        translate: "Client Orders",
        bullet: "dot",
        display:
          localStorage.getItem("PERM_TYPE") == 1 ||
          localStorage.getItem("PERM_TYPE") == 5 ||
          localStorage.getItem("POS_MGR_EMP") == 1 ||
          (localStorage.getItem("ACCOUNT_TYPE") == 11 &&
            localStorage.getItem("USER_LEVEL") == 11) ||
          (localStorage.getItem("USER_LEVEL") == 3 &&
            localStorage.getItem("ACCOUNT_TYPE") == 1)
            ? ""
            : "d-none",
      },
      {
        title: Strings["My Orders"],
        root: true,
        icon: "flaticon-app",
        page: "pages/MyOrders",
        translate: "Client Orders",
        bullet: "dot",
        display:
          localStorage.getItem("USER_LEVEL") == 3 &&
          localStorage.getItem("ACCOUNT_TYPE") == 7
            ? ""
            : "d-none",
      },
      {
        title: Strings["My Orders"],
        root: true,
        icon: "flaticon2-box-1",
        page: "orders",
        translate: "Client Orders",
        bullet: "dot",
        display:
          localStorage.getItem("PERM_TYPE") == 2 ||
          localStorage.getItem("PERM_TYPE") == 3 ||
          localStorage.getItem("PERM_TYPE") == 4
            ? ""
            : "d-none",
      },
      {
        title: Strings["Uncompleted Orders"],
        root: true,
        icon: "flaticon2-open-box",
        page: "unCompleteOrder",
        translate: "Client Orders",
        bullet: "dot",
        display: localStorage.getItem("PERM_TYPE") == 3 ? "" : "d-none",
      },
      {
        title: Strings["request"],
        root: true,
        icon: "flaticon2-open-box",
        page: "RequestOrder",
        translate: "Client Orders",
        bullet: "dot",
        display:
          localStorage.getItem("ACCOUNT_TYPE") == 9 &&
          localStorage.getItem("USER_LEVEL") == 9
            ? ""
            : "d-none",
      },
      // {
      //   title: Strings['Mobile Orders'],
      //   root: true,
      //   icon: "flaticon-app",
      //   page: "commingSoon2"
      // },
      // {
      //   title: Strings['Web Orders'],
      //   root: true,
      //   icon: "flaticon2-website",
      //   page: "commingSoon3"
      // },
      {
        title: Strings["Client Orders"],
        root: true,
        icon: "flaticon2-box-1",
        page: "ordersAdminList/2",
        display:
          (localStorage.getItem("ACCOUNT_TYPE") != undefined &&
            localStorage.getItem("USER_LEVEL") != undefined &&
            localStorage.getItem("ACCOUNT_TYPE") == "" &&
            localStorage.getItem("USER_LEVEL") == 1) ||
          // baraaHideTemp
          localStorage.getItem("USER_LEVEL") == 14 ||
          localStorage.getItem("USER_LEVEL") == 10
            ? // hide
              ""
            : "d-none",
      },
    ],
  },
};
