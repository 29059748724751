import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Builder from "./Builder";
import Dashboard from "./Dashboard";
// import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import Inventory from "../inventory/inventory";
import Pos from "../pos/pos";
import PosEmp from "../pos/posEmp";
import CommingSoon from "../errors/commingSoon";
import Orders from "../orders/orders";
import BuyCards from "../orders/buyCards";
import OrdersList from "../orders/ordersList";
import OrdersAdmin from "../orders/ordersAdmin";
import unCompleteOrder from "../orders/unCompleteOrder";
import Tools from "../tools/tools";
import RequestOrder from "../RequestOrder/RequestOrder";
import DaftraOrder from "../orders/daftraOrder";
import DaftraInventory from "../inventory/daftraInventory";
import DaftraEmp from "../pos/daftraEmp";
import Receipt from "../pos/receipt";
import OrdersAdminList from "../orders/ordersAdminList";
import { toLength } from "lodash";
import WholesaleOrders from "../orders/wholesaleOrders";
import AccountSettingsPOS from "../AccountSettingsPOS/AccountSettingsPOS";
import MyOrdersPOS from "../orders/MyOrdersPOS";
import POSBuyCards from "../orders/POSbuyCards";
import POStransactionsLog from "../orders/POStransactionsLog";
import FailedOrder from "../orders/FailedOrder";
import OrderDetailsPOS from "../orders/OrderDetailsPOS";
import POSOrderInfoWrapper from "../orders/POSOrderInfoWrapper";

const Permissions = lazy(() => import("../permissions/permissions"));
const Settings = lazy(() => import("../settings/settings"));
const Reports = lazy(() => import("../reports/reports"));
const Invoices = lazy(() => import("../invoices/invoices"));
const Accounts = lazy(() => import("../accounts/accounts"));
const DtoneConfig = lazy(() => import("../dtoneConfig/dtoneConfig"));
// const ReactBootstrapPage = lazy(() =>
//   import("./react-bootstrap/ReactBootstrapPage")
// );
function getLoggedInUser() {
  if (
    localStorage.getItem("USER_ID") != null &&
    localStorage.getItem("USER_ID") != undefined &&
    localStorage.getItem("USER_ID") != ""
  ) {
    return localStorage.getItem("USER_ID");
  } else {
    return null;
  }
}
function isUserAuthenticated() {
  // console.log("test");
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const currentTime = new Date().setDate(new Date().getDate());
  const loginDate = localStorage.getItem("loginDate");

  if (loginDate < currentTime) {
    localStorage.setItem("USER_ID", "");
    localStorage.setItem("PERM_DIST", "");
    localStorage.setItem("PERM_TYPE", "");
    localStorage.setItem("USER_TOKEN", "");
    localStorage.setItem("IS_TAXDIST", "");
    localStorage.setItem("USER_EMAIL", "");
    localStorage.setItem("loginDate", "");
    localStorage.setItem("USER_FULL_NAME", "");
    if (
      localStorage.getItem("LANG") == undefined ||
      localStorage.getItem("LANG") == "" ||
      localStorage.getItem("LANG") == null
    ) {
      localStorage.setItem("LANG", 2);
    }
    localStorage.setItem("SHOW_COUPONS", "");
    window.location = "/login";
  } else {
    return true;
  }
  return true;
}
export default function HomePage() {
  isUserAuthenticated();
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <Route path="/builder" component={Builder} /> */}
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/AccountSettingsPOS" component={AccountSettingsPOS} />
        <Route path="/pages/MyOrders" component={MyOrdersPOS} />
        <Route path="/FailedOrder/:id" component={FailedOrder} />
        <Route path="/myOrder/:id" component={POSOrderInfoWrapper} />

        <Route path="/inventory/:mainDist?/:posId?" component={Inventory} />
        <Route path="/pos" component={Pos} />
        <Route path="/distributors" component={Pos} />

        <Route path="/posEmp/:pageType?" component={PosEmp} />
        <Route path="/commingSoon" component={CommingSoon} />
        <Route path="/orders/:orderId?" component={Orders} />
        <Route path="/ordersList/:orderId?" component={OrdersList} />
        <Route
          path="/ordersAdminList/:pageType?/:orderId?"
          component={OrdersAdminList}
        />
        <Route path="/ordersAdmin" component={OrdersAdmin} />
        <Route path="/settings" component={Settings} />
        <Route path="/reports" component={Reports} />
        <Route path="/invoices" component={Invoices} />
        <Route path="/buyCards" component={BuyCards} />
        <Route path="/POSbuyCards" component={POSBuyCards} />
        <Route path="/unCompleteOrder" component={unCompleteOrder} />
        <Route path="/tools" component={Tools} />
        <Route path="/RequestOrder" component={RequestOrder} />
        <Route path="/permissions" component={Permissions} />
        <Route path="/wholesaleOrders" component={WholesaleOrders} />
        <Route path="/accounts" component={Accounts} />
        <Route path="/dtoneConfig" component={DtoneConfig} />

        {/* DaftraPage Add in 20-01-2021----Start */}
        <Route path="/daftraOrder" component={DaftraOrder} />
        <Route path="/daftraInventory" component={DaftraInventory} />
        <Route path="/daftraEmp" component={DaftraEmp} />
        <Route path="/receipt/:posId?" component={Receipt} />
        {/* DaftraPage Add in 20-01-2021----End */}

        {/* <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        {/* <Route path="/docs" component={DocsPage} /> */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
