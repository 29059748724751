import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { useSelector } from "react-redux";
// import {
//   Portlet,
//   PortletBody,
//   PortletHeader,
//   PortletHeaderToolbar
// } from "../../partials/content/Portlet";
// import { metronic } from "../../../_metronic";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import DashboardReport from "../reports/dashboard"

export default function Dashboard() {
  const [isTechAdmin, setIsTechAdmin] = useState(0);
  // console.log(localStorage.getItem('loginDate'))
  const dispatch = useDispatch()
  useEffect(() => {
    // console.log("update submit resuilt Dashboard ^^ ");
    if (localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('POS_MGR_EMP') == 1) {
      getUserBalance()
    } else if (localStorage.getItem('USER_LEVEL') == 1) {
      is_tech_admin()
    }
  }, [])
  const getUserBalance = () => {
    fetch(window.API_PERM_USER, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'getUserBalance',
        'lang': localStorage.getItem('LANG'),
        'userId': localStorage.getItem('USER_ID'),
        'token': localStorage.getItem('USER_TOKEN'),
      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (data !== "Nothing found") {
        if (data['logout'] == 1) {
          localStorage.setItem('USER_ID', '');
          localStorage.setItem('USER_TOKEN', '');
          window.location.href = "/logout";
        } else {
          localStorage.setItem('USER_BALANCE_FROM', 'redux');
          // console.log("call distpatch");
          dispatch({
            type: 'UPDATEBALANCE',
            payload: data?.balanceCurr ? data?.balanceCurr : '0'
          })
        }
      }
    })
  }
  const is_tech_admin = () => {
    fetch(window.API_PERM_USER, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'is_tech_admin',
        'lang': localStorage.getItem('LANG'),
        'userId': localStorage.getItem('USER_ID'),
        'token': localStorage.getItem('USER_TOKEN'),
      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (data !== "Nothing found") {
        if (data['logout'] == 1) {
          localStorage.setItem('USER_ID', '');
          localStorage.setItem('USER_TOKEN', '');
          window.location.href = "/logout";
        } else {
          // console.log(data);
          setIsTechAdmin(data['isMain']);
        }
      }
    })
  }
  return (
    <div>
      {(localStorage.getItem('PERM_TYPE') == 2 || localStorage.getItem('PERM_TYPE') == 5 || (localStorage.getItem('USER_LEVEL') == 1 && (isTechAdmin == true || isTechAdmin == 1))) ?
        <div>
          <DashboardReport />
        </div>
        :
        <div className='dashboard-main-view'>
          <img className='img-fluid dashboard-logo' alt="logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO" + window.MAIN_IMAGE + ".png")} />
          <p className='dashboard-main-title'>CRM &  System</p>
        </div>
      }

    </div>
  );
}
